<!--
 * @Author: 漫步云端
 * @Date: 2024-03-12 17:42:54
 * @saying: 每天要开心哦！
-->
<template>
    <div class="sideBar" :style="{ width: width + 'px' }">
        <el-menu :default-active="$route.path" router class="el-menu-vertical-demo" :collapse-transition="false"
            background-color="#F6F9FF" text-color="#000" active-text-color="#0071FF" :collapse="collapse">
            <el-menu-item index="/Layout/home">
                <i class="el-icon-menu"></i>
                <span slot="title">首页</span>
            </el-menu-item>
            <el-menu-item index="/Layout/VideoUploadSplit">
                <i class="el-icon-user"></i>
                <span slot="title">数字形象</span>
            </el-menu-item>
            <el-menu-item index="/Layout/myVoice">
                <i class="el-icon-headset"></i>
                <span slot="title">数字声音</span>
            </el-menu-item>
            <el-menu-item index="/Layout/create">
                <i class="el-icon-picture-outline"></i>
                <span slot="title">创作空间</span>
            </el-menu-item>
            <el-menu-item index="/Layout/microlesson">
                <i class="el-icon-video-camera"></i>
                <span slot="title">数字微课</span>
            </el-menu-item>

            <el-menu-item index="/Layout/project">
                <i class="el-icon-s-operation"></i>
                <span slot="title">数字项目</span>
            </el-menu-item>

            <el-menu-item index="/Layout/taskConsole">
                <i class="el-icon-data-line"></i>
                <span slot="title">任务控制台</span>
            </el-menu-item>

        </el-menu>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    data() {
        return {
        }
    },
    methods: {
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        }
    },
    computed: {
        ...mapState(["collapse", "width"]),
    },

}
</script>
<style scoped lang='scss'>
.sideBar {

    height: 100%;
}

.el-menu {
    border: none;
}

.el-menu-item:hover {
    background: #EBF4FF !important;
}
</style>
