/*
 * @Author: 漫步云端
 * @Date: 2024-03-12 09:55:14
 * @saying: 每天要开心哦！
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'normalize.css'
import axios from 'axios'
import uploader from 'vue-simple-uploader';
import 'animate.css';
Vue.prototype.$axios= axios
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(uploader)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
