import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    collapse:false,//左侧菜单是否水平折叠
    width:160,//左侧菜单宽
    
  },
  getters: {
  },
  mutations: {
     //左侧菜单是否水平折叠
     CHANGE_COLLAPSE(state) {
      state.collapse = !state.collapse
      if(state.collapse){
        state.width=64
      }else{
        state.width=160
      }
    },
  },
  actions: {
  },
  modules: {
  }
})
