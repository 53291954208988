<template>
  <div class="login">
    <div class="main">
      <h2 class="h2">账号密码登录</h2>
      <div class="tip">请使用已有的账号密码进行登录</div>
      <div class="form">
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="" class="demo-ruleForm">
          <el-form-item label="" prop="pass">
            <el-input v-model="ruleForm.pass" prefix-icon="el-icon-user-solid"></el-input>
          </el-form-item>
          <el-form-item label="" prop="checkPass" class="password">
            <el-input type="password" v-model="ruleForm.checkPass" prefix-icon="el-icon-lock"></el-input>
          </el-form-item>
          <el-form-item label="" class="checked">
            <el-checkbox v-model="ruleForm.checked" @change="rember">记住密码</el-checkbox>
          </el-form-item>
          <el-form-item>
            <!-- <el-button class="loginBtn" @click="submitForm('ruleForm')">提交</el-button> -->
            <button class="loginBtn" @click="submitForm('ruleForm')">登录</button>
          </el-form-item>
        </el-form>


        <!-- <el-input v-model="acount" placeholder="请输入账号" prefix-icon="el-icon-user-solid"
          :validate-event="true"></el-input>
        <el-input v-model="password" placeholder="请输入密码" type="password" :show-password="true"
          prefix-icon="el-icon-lock"></el-input>
        <el-checkbox v-model="checked" @change="rember">记住密码</el-checkbox> 
        <button class="loginBtn">登录</button> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入账号'));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        callback();
      }
    };
    return {
      acount: '',
      password: '',
      checked: true,
      ruleForm: {
        pass: '',
        checkPass: '',
        checked: true,
      },
      rules: {
        pass: [
          { validator: validatePass, }
        ],
        checkPass: [
          { validator: validatePass2, }
        ]
      }
    };
  },
  methods: {
    //记住密码  e==checked的值
    rember(e) {

    },
    //登录
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$router.push({
            path: '/Layout'
          })
        } else {
          this.$message.error('登录失败');
          return false;
        }
      });
    },

  }
}
</script>
<style scoped lang='scss'>
.login {
  width: 100%;
  height: 100%;
  background: #D9DAFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main {
  background: #E5EDFE;
  width: 300px;
  border-radius: 10px;
  padding: 0 40px;

}

.form {
  text-align: left;
}

.h2 {
  margin-bottom: 6px;
}

.tip {
  color: #A4AFBA;
  font-size: 12px;
  margin-bottom: 30px;
}

.loginBtn {
  background: #6B5DD3;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 20px;
  color: #fff;
  margin: 10px 0 30px;
}

.password {
  margin-bottom: 10px;
}

.checked {
  margin-bottom: 0;
}
</style>
