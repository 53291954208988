import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login/login.vue'
import Layout from '../layout/index.vue'

Vue.use(VueRouter)

//解决重复点击相同路由的问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
 {
  path: '/',
  redirect: '/Layout'
},

{
  path: '/Login',
  name: 'Login',
  component: Login
},

{
  path: '/Layout',
  name: 'Layout',
  component: Layout,
  redirect: '/Layout/home',
  children: [//二级路由
    {
      path: '/Layout/home',
      component: ()=>import('../views/home/home.vue')
    },
    {
      path: '/Layout/create',
      component: ()=>import('../views/create/create.vue')
    },
    {
      path: '/Layout/microlesson',
      component: ()=>import('../views/microlesson/microlesson.vue')
    },
    // {
    //   path: '/Layout/VideoPlay',
    //   component: ()=>import('../views/VideoPlay/VideoPlay.vue')
    // },
    {
      path: '/Layout/myVoice',
      component: ()=>import('../views/VideoPlay/myVoice.vue')
    },
    // {
    //   path: '/Layout/VideoUploadSplit',
    //   component: ()=>import('../views/VideoUploadSplit/VideoUploadSplit.vue')
    // },
    {
      path: '/Layout/VideoUploadSplit',
      component: ()=>import('../views/VideoUploadSplit/cloned.vue')
    },
    {
      path: '/Layout/project',
      component: ()=>import('../views/project/project.vue')
    },
    {
      path: '/Layout/taskConsole',
      component: ()=>import('../views/taskConsole/taskConsole.vue')
    }
  ]
},
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
