<template>
    <div class="layout">
        <div class="header">
            <img class="headImg" @click="CHANGE_COLLAPSE" src="../assets/img/logo.png" alt="">
            <div class="head_right">
                <el-popover placement="bottom-end" width="100" trigger="hover">
                    <div>
                        <div class="dropdown-item"><i class="el-icon-user"></i>个人中心</div>
                        <!-- <div class="dropdown-item"><i class="el-icon-goods"></i>兑换时长</div> -->
                        <div class="dropdown-item"><i class="el-icon-switch-button"></i>退出登录</div>
                    </div>
                    <div class="profile-text" slot="reference">
                        <img class="avatar" src="../assets/img/avatar.png" alt="">
                        <div class="userNameStyle">您好，{{ userName }}</div>
                    </div>
                </el-popover>
            </div>
        </div>
        <!-- <nav>
            <router-link to="/">Home</router-link> |
            <router-link to="/Layout/create">create</router-link>
        </nav> -->
        <div class="main">
            <menu class="menu">
                <sideBar></sideBar>
            </menu>
            <div class="content">
                <router-view />
            </div>
        </div>

    </div>
</template>

<script>
import sideBar from "./sideBar";
import { mapMutations } from "vuex";
export default {
    components: {
        sideBar
    },
    data() {
        return {
            visible: false,
            userName: '超级管理员',
        }
    },
    methods: {
        ...mapMutations(["CHANGE_COLLAPSE"]),
    }
}
</script>
<style scoped lang='scss'>
.layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    height: 54px;
    background: url('../assets/img/head1.png') no-repeat center/cover;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headImg {
    height: 50px;
    vertical-align: bottom;
    margin-left: 10px;
}

.head_right {
    padding: 0 40px;
    color: #001632;
    font-size: 12px;
    display: flex;
    align-items: center;

    .profile-text {
        display: flex;
        align-items: center;
    }

    .userNameStyle {
        font-size: 12px;
        font-weight: bold;
    }

    .avatar {
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    text-align: inherit;
    background-color: transparent;
    border: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-decoration: none;
    box-sizing: border-box;
    white-space: normal;
    font-weight: 500;
    color: #9b9b9b;
    font-size: 12px;
    cursor: pointer;
}

.dropdown-item:hover {
    text-decoration: none;
    background-color: #f8f9fa;
}

.dropdown-item i {
    color: #2478fd;
    margin-right: 5px;
}

.main {
    flex: 1;
    display: flex;
}

.menu {
    // width: 180px;
    padding: 0;
    margin: 0;
    // background-color: #F8FBFF;
    background: linear-gradient(#F6F9FF, 85%, #EAFAFF);
}

.content {
    height: calc(100vh - 54px);
    overflow: auto;
    flex: 1;
}
</style>
